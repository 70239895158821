import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import { HttpCodes, urls } from "~/api/config";
import { useTransport } from "~/composables/useTransport";
import { useStoresStore } from "~/store/stores";
import { formatNumberWithSpaces } from "~/utils/etc";
import type { IProduct } from "~/components/VProduct/VProduct.types";
import { Routes } from "~/utils/routes";
import { useCategoriesStore } from "~/store/categories";

export interface IPromoAction {
  id: string;
  name: string;
  slug?: string;
}

export type IPromoProductProgressiveDiscountType =
  | "cashback"
  | "percent"
  | "price"
  | "magnets"
  | "bonuses";

export interface IPromoProductProgressiveDiscountValue {
  count: number;
  label?: string;
  countLabel: string;
  value: number;
}

export interface IPromoProduct {
  alcohol?: boolean;
  articleCategory?: string;
  productCode?: string;
  barcode?: string;
  categoryName?: string;
  crmCode?: string;
  description?: string;
  discountCategory?: string;
  discountLabel?: string;
  discountPercentage?: number;
  discountShopImage?: string;
  discountType?: string;
  discountValue?: number;
  endDate?: string; // "2024-01-01"
  id: string;
  image?: string;
  imageUrl?: string;
  name?: string;
  slug: string;
  url: string;
  oldPrice?: number;
  previewDiscountTitle?: string;
  price?: number;
  priority?: number;
  progressiveDiscount?: IPromoProductProgressiveDiscountValue[];
  progressiveDiscountType?: IPromoProductProgressiveDiscountType;
  publisher?: string;
  showDate?: string;
  startDate?: string; // "2024-01-01"
  type?: string;
  unit?: string;
  unit1?: string;
}

export interface IPromoProducts {
  items: IPromoProduct[];
  total: number;
}

export interface IPromoFilteredProducts {
  items: IPromoProduct[];
  total: number;
}

interface IPromoStatus {
  categoryProducts: "initial" | "pending" | "success" | "error";
  filteredProducts: "initial" | "pending" | "success" | "error";
}

export interface IRequestProductsParams {
  categoryId?: string | number;
  storeId?: string | number;
  adult?: boolean;
  sortBy?: string;
  order?: string;
  limit?: number;
  offset?: number;
}

interface IRequestPromoSearch {
  query: string;
  storeCode?: string;
  limit?: number;
  offset?: number;
}

export const usePromoStore = defineStore("promo", () => {
  const { requestShopCode, requestShopId } = storeToRefs(useStoresStore());
  const categoriesStore = useCategoriesStore();

  // TODO remove hardcode when actions api will be ready
  const actions = ref<IPromoAction[]>([
    { id: "1", name: "Скидка 20%" },
    { id: "2", name: "Товар недели" },
    { id: "3", name: "День рождения" },
    { id: "4", name: "Выгодные недели" },
    { id: "5", name: "День красоты" },
    { id: "6", name: "Бренд месяца" },
    { id: "7", name: "Снижение цены" },
    { id: "8", name: "Фестивали" },
  ]);
  const categoryProducts = ref<IPromoProducts>({
    items: [],
    total: 0,
  });
  const newProducts = ref<IPromoProduct[]>([]);
  const filteredProducts = ref<IPromoFilteredProducts>({
    items: [],
    total: 0,
  });

  const status = reactive<IPromoStatus>({
    categoryProducts: "initial",
    filteredProducts: "initial",
  });

  async function requestCategoryProducts(
    params: IRequestProductsParams,
    additionally = false,
  ) {
    status.categoryProducts = "pending";

    const { data, error } = await requestProducts(params);

    if (data.value) {
      if (!data.value.data.length && data.value.total) {
        throw createError({
          statusCode: 404,
          message: "Wrong response with to much count of promo products",
          fatal: true,
        });
      }

      categoryProducts.value = {
        items: additionally
          ? [...categoryProducts.value.items, ...data.value.data]
          : data.value.data,
        total: data.value.total,
      };
      status.categoryProducts = "success";
    }

    if (error.value) {
      status.categoryProducts = "error";

      if (
        error.value.statusCode &&
        error.value.statusCode >= HttpCodes.Error4xx &&
        error.value.statusCode < HttpCodes.Error5xx
      ) {
        throw createError({
          statusCode: 404,
          message: "Wrong response of promo products",
          fatal: true,
        });
      }
    }
  }

  async function requestProducts(params: IRequestProductsParams) {
    if (!params.categoryId) {
      delete params.categoryId;
    }

    const { storeId, adult = true, ...etc } = params;

    const { data, error } = await useTransport<{
      data: IPromoProduct[];
      total: number;
    }>(urls.promo.products, {
      method: "GET",
      query: {
        storeId: storeId || requestShopId.value,
        adult,
        ...etc,
      },
    });

    if (data.value) {
      data.value.data = appendUrl(data.value.data);
    }
    return { data, error };
  }

  async function requestFilteredProducts(params: IRequestProductsParams) {
    status.filteredProducts = "pending";

    const { data, error } = await requestProducts({
      limit: PROMO_PRODUCTS_LIMIT,
      offset: 0,
      ...params,
    });

    if (data.value) {
      filteredProducts.value = {
        items: data.value.data,
        total: data.value.total,
      };
      status.filteredProducts = "success";
    }

    if (error.value) {
      status.filteredProducts = "error";
    }
  }

  function appendUrl(products: IPromoProduct[]): IPromoProduct[] {
    return products.map((product) => {
      return { ...product, url: makePromoProductUrl(product) };
    });
  }

  function makePromoProductUrl(product: IPromoProduct): string {
    const category = product.articleCategory
      ? categoriesStore.getCategoryById(product.articleCategory, "promo")
      : null;
    return category
      ? `${Routes.PromoProduct}/${product.id}-${product.slug}?shopCode=${requestShopCode.value}`
      : "";
  }

  const getPromoSearch = (params: IRequestPromoSearch) => {
    const { storeCode, ...etc } = params;

    return useTransport<{
      data: IPromoProduct[];
      total: number;
    }>(urls.promo.search, {
      params: {
        storeCode: storeCode || requestShopCode.value,
        ...etc,
      },
    });
  };

  return {
    requestCategoryProducts,
    requestFilteredProducts,
    requestProducts,
    makePromoProductUrl,
    getPromoSearch,
    actions,
    categoryProducts,
    newProducts,
    filteredProducts,
    status,
  };
});

export function formatPromoProduct(product: IPromoProduct): IProduct {
  return {
    id: product.id,
    title: product.name,
    image: product.imageUrl,
    price: product.price
      ? formatNumberWithSpaces(product.price / 100)
      : undefined,
    oldPrice: product.oldPrice
      ? formatNumberWithSpaces(product.oldPrice / 100)
      : undefined,
    badge: product.discountCategory,
    salePercent: product.discountLabel
      ? String(product.discountLabel)
      : undefined,
    adult: false,
    progressiveDiscountType: product.progressiveDiscountType,
    progressiveDiscountVariants: product.progressiveDiscount,
  };
}
